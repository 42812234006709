<template>
  <section class="casestudy">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <!-- <p class="tags">Business Study by Trends | Case Study</p> -->
          <div class="tabs">
            <div class="tabs__nav">
              <div
                v-for="(study, index) in list"
                :key="index"
                :class="[index === active ? 'tab-active' : '']"
                @click="activate(index)"
              >
                <div id="headingtab">
                  <h3>
                    {{ study.title }}
                  </h3>
                  <p>
                    {{ study.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 pt-4">
          <div class="">
            <img
              v-if="imageBanner()"
              :src="`../webassets/images/${imageBanner()}.jpg`"
              class="serviceimage"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <Features :feature="list[active]" />
</template>

<script>
import Features from "@/components/landingPage/Features.vue";
import axios from "axios";
export default {
  data() {
    return {
      active: 0,
      lang: "en",
      list: [],
    };
  },
  components: {
    Features,
  },
  beforeMount() {
    this.ActionUpdateStore();
  },

  methods: {
    imageBanner() {
      if (this.list[this.active] === undefined) {
        return "";
      }
      if (this.list[this.active].case_study_banner_image) {
        return this.list[this.active].case_study_banner_image;
      } else {
        return "";
      }
    },
    activate(index) {
      this.active = index;
    },
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/landing_page/collections/business_studies/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
        sort_by: [
          {
            field: "order",
            order: "asc",
          },
        ],
      };

      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.list = [];
            return;
          } else if (data.data.length == 0) {
            this.list = [];
            return;
          }
          this.list = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
#headingtab {
  padding: 0px 12px;
  cursor: pointer;
}
.tab-active {
  border-left: 5px solid #0880e8;
}
.datatext {
  padding: 0px 10px;
}
.casestudy {
  padding: 50px 0;
  background: #f9f9f9;
}
.casestudy h3 {
  font-weight: 500;
  line-height: 1.3em;
  font-size: 25px;
}
.casestudy .tags {
  font-size: 14px;
}
img.casestudyimage {
  width: 100%;
}
@media (max-width: 1200px) and (min-width: 999px) {
  img.casestudyimage {
    margin-top: 40px;
  }
  .casestudy h3 {
    font-size: 23px;
  }
}
@media (max-width: 998px) and (min-width: 450px) {
  img.casestudyimage {
    margin-top: 90px;
  }
}
@media (max-width: 451px) and (min-width: 0px) {
  img.casestudyimage {
    margin-top: 10px;
  }
}
@media (max-width: 998px) {
  .casestudy {
    padding: 30px !important;
  }
  .casestudy h3 {
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
