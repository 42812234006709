<template>
  <Carousel
    :navigation="true"
    :pagination="true"
    :startAutoPlay="true"
    :timeout="5000"
    class="carouselhome"
    v-slot="{ currentSlide }"
  >
    <Slide v-for="(slideItem, index) in slides" :key="index">
      <div v-show="currentSlide === index + 1" class="slide-info">
        <video class="img-fluid" autoplay loop muted>
          <source
            :src="`../webassets/videos/${slideItem.bg_file}.mp4`"
            type="video/mp4"
          />
        </video>
        <div claSS="innertext">
          <h1 class="carouselTitle">
            {{ slideItem.title }}
          </h1>
          <p class="carouselsubtitle">
            {{ slideItem.description2 }}
          </p>
          <p class="carouseldesc">
            {{ slideItem.description }}
          </p>
        </div>
      </div>
    </Slide>
  </Carousel>
</template>

<script>
import Carousel from "@/components/carousel/Carousel.vue";
import Slide from "@/components/carousel/Slide.vue";
import axios from "axios";
export default {
  components: { Carousel, Slide },
  data() {
    return {
      lang: "en",
      slides: {
        title: "",
        description: "",
        bg_file: "",
      },
    };
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/landing_page/collections/intro_slides/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
        sort_by: [
          {
            field: "order",
            order: "asc",
          },
        ],
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.slides = {};
            return;
          } else if (data.data.length == 0) {
            this.slides = {};
            return;
          }

          this.slides = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.innertext {
  width: 80%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.carouselhome {
  position: relative;
  max-height: 100vh;
  height: 100vh;
}
.carouselhome .slide-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  height: 100%;
}
.carouselhome img {
  min-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.carouselTitle {
  color: #fff;
  font-size: 60px;
}
.carouselsubtitle {
  color: #fff;
  font-size: 25px;
}
.carouseldesc {
  color: #fff;
  font-size: 18px;
}
@media (max-width: 998px) {
  .innertext {
    width: 65%;
  }
  .carouselTitle {
    color: #fff;
    font-size: 45px;
  }
  .carouselsubtitle {
    color: #fff;
    font-size: 20px;
  }
  .carouseldesc {
    color: #fff;
    font-size: 14px;
  }
  .innertext {
    top: 45% !important ;
  }
}
video.img-fluid {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
@media (min-width: 998px) {
  .navigate {
    top: 10%;
  }
}
</style>
