<template>
  <Header />
  <CarouselHome />
  <ServiceOffered />
  <CaseStudies />
  <!-- <Features /> -->
  <!-- <DataArchitecture style="background: #f9f9f9" /> -->
  <!-- <Reviews /> -->
  <!-- <Faq /> -->
  <!-- <Question /> -->
  <Blogs />
  <NewsLetter />
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import CarouselHome from "@/components/landingPage/carouselHome.vue";
import ServiceOffered from "@/components/landingPage/ServiceOffered.vue";
// import DataArchitecture from "@/components/landingPage/DataArchitecture.vue";
import CaseStudies from "@/components/landingPage/CaseStudies.vue";
// import Features from "@/components/landingPage/Features.vue";
// import Faq from "@/components/landingPage/Faq.vue";
// import Question from "@/components/landingPage/Question.vue";
// import Reviews from "@/components/landingPage/Reviews.vue";
import Blogs from "@/components/landingPage/Blogs.vue";
import NewsLetter from "@/components/NewsLetter.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    CarouselHome,
    ServiceOffered,
    // Features,
    CaseStudies,
    // DataArchitecture,
    // Reviews,
    // Faq,
    // Question,
    Blogs,
    NewsLetter,
    Footer,
  },
};
</script>
