<template>
  <section id="features" class="services">
    <div class="container" data-aos="fade-up">
      <div class="section-title text-center p-4">
        <h1>Service Offerings</h1>
      </div>

      <div class="row pt-4">
        <div
          class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch"
          v-for="(obj, key) in Services"
          :key="key"
        >
          <div class="icon-box" @click.prevent="$router.push(obj.linktext)">
            <img
              :src="`../webassets/images/${obj.path}.jpg`"
              class="serviceimage"
            />
            <h4 class="softwarehead">{{ obj.service_tittle }}</h4>
            <p style="margin-bottom: 0px">{{ obj.service_content }}</p>
            <p><a :href="`/${obj.linktext}`" class="ReadMore">Read More</a></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      lang: "en",
      Services: {
        path: "",
        service_content: "",
        service_tittle: "",
      },
    };
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/landing_page/collections/service_offerings/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
        sort_by: [
          {
            field: "order",
            order: "asc",
          },
        ],
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.Services = {};
            return;
          } else if (data.data.length == 0) {
            this.Services = {};
            return;
          }
          this.Services = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.softwarehead {
  font-weight: 600;
}
.icon-box:hover {
  background: #f7f9fd;
  border-radius: 10px;
  cursor: pointer;
  border-color: #0880e8 !important;
}
img.serviceimage {
  width: 100%;
  padding-bottom: 30px;
  border-radius: 15px;
}
section#features {
  padding: 30px;
}
a.ReadMore {
  color: #0880e8;
}
.services .icon-box {
  margin-bottom: 20px;
  border-radius: 15px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.services .icon-box h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5em;
  padding: 0px 25px;
  color: #0880e8;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 16px;
  padding: 10px 25px;
  margin-left: 0px !important;
}
@media (max-width: 998px) and (min-width: 0px) {
  section#features {
    padding: 40px;
  }
}

.icon-box:hover {
  box-shadow: 0 3px 5px rgb(0 0 0 / 9%);
  transform: translateY(-5px);
}
</style>
